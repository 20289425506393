import * as React from 'react'
import { Localized } from '@fluent/react'
import { validateEmail } from '../../../helpers'

import './index.css'

const leadingSpace = new RegExp(/^\s+/)

interface CommonProps {
  isValid?: (status: boolean) => void
  l10nId?: string
  errorMessage?: string
  autoFocus?: boolean
  disabled?: boolean
  required?: boolean
}

interface CommonTextProps {
  type?: 'text'
  value?: string
  trim?: boolean
  minLength?: number
  maxLength?: number
}

interface OptionalTextProps extends CommonTextProps {
  required?: false
  onChange: (value: string | null) => void
  validator?: (value: string | null) => boolean
}

interface RequiredTextProps extends CommonTextProps {
  required: true
  onChange: (value: string) => void
  validator?: (value: string) => boolean
}

type TextProps = OptionalTextProps | RequiredTextProps

interface PasswordProps {
  type: 'password'
  onChange: (value: string) => void
  validator?: (value: string) => boolean
  value?: string
  minLength?: number
  maxLength?: number
  sameAs?: string
}

interface CommonEmailProps {
  type: 'email'
  value?: string
}

interface OptionalEmailProps extends CommonEmailProps {
  required?: false
  onChange: (value: string | null) => void
  validator?: (value: string | null) => boolean
}

interface RequiredEmailProps extends CommonEmailProps {
  required: true
  onChange: (value: string) => void
  validator?: (value: string) => boolean
}

type EmailProps = OptionalEmailProps | RequiredEmailProps

interface CheckboxProps {
  type: 'checkbox'
  onChange: (value: boolean) => void
  value?: boolean
}

interface CommonNumberProps {
  type: 'number'
  value?: number
}

interface OptionalNumberProps extends CommonNumberProps {
  required?: false
  onChange: (value: number | null) => void
  validator?: (value: number | null) => boolean
}

interface RequiredNumberProps extends CommonNumberProps {
  required: true
  onChange: (value: number) => void
  validator?: (value: number) => boolean
}

interface InputState {
  touched: boolean
  inputVal: string | number | boolean | null
  focused: boolean
}

type NumberProps = OptionalNumberProps | RequiredNumberProps

type TypeProps = TextProps | PasswordProps | EmailProps | CheckboxProps | NumberProps

type InputProps = CommonProps & TypeProps

class Input extends React.Component<InputProps> {
  state: InputState = {
    touched: false,
    inputVal: '',
    focused: false,
  }

  unTouch = () => {
    this.setState({ touched: false })
  }

  private changeInputVal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.state.touched) {
      this.setState({ touched: true })
    }

    const input = e.target as HTMLInputElement
    let inputVal

    if (input.value === '') {
      inputVal = null
    } else if (this.props.type === 'checkbox') {
      inputVal = input.checked
    } else if (this.props.type === 'text' && this.props.trim) {
      inputVal = input.value.replace(leadingSpace, '')
    } else if (this.props.type === 'number') {
      inputVal = Number(input.value)
    } else {
      inputVal = input.value
    }

    this.setState({ inputVal })
    if (this.props.required) {
      if (inputVal != null) {
        (this.props.onChange as (value: number | string | boolean) => void)(inputVal)
      }
    } else {
      (this.props.onChange as (value: number | string | boolean | null) => void)(inputVal)
    }
  }

  private onFocus = () => {
    this.setState({ focused: true })
  }

  private onBlur = () => {
    this.setState({ focused: false })
  }

  private validateInput = (): { status: boolean, classes: string } => {
    const { touched, inputVal, focused } = this.state

    let status = true
    const classes = ['input']

    if (touched) {
      if (this.props.required && inputVal == null) {
        status = false
      }
      if ('minLength' in this.props && this.props.minLength) {
        if (inputVal == null || (inputVal as string).length < this.props.minLength) {
          status = false
        }
      }
      if ('maxLength' in this.props && this.props.maxLength) {
        if (inputVal == null || (inputVal as string).length > this.props.maxLength) {
          status = false
        }
      }
      if (this.props.type === 'email') {
        if (!validateEmail(inputVal as string)) {
          status = false
        }
      }
      if ('sameAs' in this.props && this.props.sameAs) {
        if (inputVal !== this.props.sameAs) {
          status = false
        }
      }
      if ('validator' in this.props && this.props.validator) {
        const { validator } = this.props
        if (this.props.required && inputVal !== null) {
          if (!(validator as (value: number | string | boolean) => boolean)(inputVal)) {
            status = false
          }
        } else if (!this.props.required) {
          if (!(validator as (value: number | string | boolean | null) => boolean)(inputVal)) {
            status = false
          }
        }
      }

      if (status) {
        classes.push('valid')
      } else {
        classes.push('invalid')
      }
    }

    if (this.props.isValid) {
      this.props.isValid(status)
    }

    if (focused) {
      classes.push('focused')
    }

    return { status, classes: classes.join(' ') }
  }

  componentDidUpdate = (prevProps: InputProps) => {
    if (prevProps.value !== this.props.value && this.props.value !== undefined) {
      if (this.props.type === 'text' && this.props.trim) {
        this.setState({ inputVal: this.props.value.replace(leadingSpace, '') })
      } else {
        this.setState({ inputVal: this.props.value })
      }
    }
  }

  componentDidMount = () => {
    if (this.props.value) {
      if (this.props.type === 'text' && this.props.trim) {
        this.setState({ inputVal: this.props.value.replace(leadingSpace, '') })
      } else {
        this.setState({ inputVal: this.props.value })
      }
    }
  }

  public render() {
    const { touched, inputVal } = this.state
    const { l10nId, errorMessage, type, autoFocus, disabled = false } = this.props

    const classes = this.validateInput().classes

    const value = type === 'checkbox'
      ? undefined
      : inputVal != null || !this.props.required
        ? (inputVal ?? '') as string | number
        : type === 'number'
          ? 0
          : ''

    let input =
      <input
        id={l10nId}
        type={type ? type : 'text'}
        value={value}
        checked={type === 'checkbox' ? inputVal as boolean : undefined}
        autoFocus={typeof autoFocus === 'boolean' ? autoFocus : false}
        onChange={this.changeInputVal}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        disabled={disabled}
        data-testid='input'
      />

    if (l10nId) {
      input = <Localized id={l10nId} attrs={{ placeholder: true }}>
        {input}
      </Localized>
    }

    return (
      <div className={classes} data-testid='input-wrapper'>
        {input}
        {
          touched && !this.validateInput().status && errorMessage ?
            <span className="input__error">
              <Localized id={errorMessage}>
                {errorMessage}
              </Localized>
            </span>
            : null
        }
      </div>
    )
  }
}

export default Input
