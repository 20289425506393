import * as React from 'react'
import { Editor, Node } from 'slate'
import { useSlate } from 'slate-react'
import { Suggestion, SuggestionsEditor } from 'slate-suggestions'
import { PersistingEditor } from 'slate-persistence'
import store from '../../../../../store'
import { addAlert } from '../../../../../store/actions/alerts'
import ProcessVersion from '../../../../../api/processversion'
import { Draft, Module } from '../../../../../api'
import { Link, ProcessSingleStep } from '../../../../../api/process'
import { StorageContext } from '../../../../../api/storage'
import DetailsDialog from './components/DetailsDialog'
import InvalidReferencesDialog from './components/InvalidReferencesDialog'
import SuggestionsDialog from './components/SuggestionsDialog'
import WrongTargetDialog, { WrongTargetL10n } from './components/WrongTargetDialog'
import ConfirmDialog from './components/ConfirmDialogs'
import ValidationErrorsDialog from '../../../../../components/ValidationErrorsDialog'
import Button from '../../../../../components/ui/Button'
import { INVALID_REFERENCE_CLASS } from '../../constants'
import { CurrentDraftContext } from '../..'
import './index.css'
import {
  countHighlightsAndMediaTexts,
  isFinishStep,
  stepHasPermission,
} from './util'

interface StepChangerProps {
  draft: Draft
  document: Node[]
  hasChanges: boolean
  onStepChange: () => any
}

type WrongTargetState = {
  show: boolean
  l10n: WrongTargetL10n
  highlights: number,
  mediaTexts: number,
}

const initialStateWrongTarget: WrongTargetState = {
  show: false,
  l10n: 'step-changer-dialog-wrong-target',
  highlights: 0,
  mediaTexts: 0,
}

type SuggestionsState = {
  show: boolean
  counter: number
}

const initialStateSuggestions: SuggestionsState = {
  show: false,
  counter: 0,
}

const StepChanger = (props: StepChangerProps) => {
  const editor = useSlate()
  const storage = React.useContext(StorageContext)
  const [validationErrors, setValidationErrors] = React.useState<string | null>(null)
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const [showDetailsDialog, setShowDetailsDialog] = React.useState(false)
  const [invalidReferences, setInvalidReferences] = React.useState<Element[]>([])
  const [link, setLink] = React.useState<Link | null>(null)
  const [wrongTarget, setWrongTarget] = React.useState(initialStateWrongTarget)
  const [suggestions, setSuggestions] = React.useState(initialStateSuggestions)
  const [isAdvancing, setIsAdvancing] = React.useState(false)
  const currentDraftContext = React.useContext(CurrentDraftContext)

  const displayConfirmDialog = async (link: Link) => {
    const draftPermissions = currentDraftContext.state.currentDraftPermissions

    let targetStep: ProcessSingleStep | undefined
    try {
      const [processId, versionId] = props.draft.step!.process
      targetStep = await ProcessVersion.loadStep(processId, versionId, link.to)
    } catch {
      setWrongTarget(prevState => ({
        ...prevState,
        show: true,
        l10n: 'step-changer-dialog-wrong-target',
      }))
      return
    }

    const invalidReferences = Array.from(
      document.querySelectorAll(`.${INVALID_REFERENCE_CLASS}:not(.suggestion--remove)`),
    )
    if (invalidReferences.length) {
      setInvalidReferences(invalidReferences)
      return
    }

    if (isFinishStep(targetStep)) {
      const [highlights, mediaTexts] = countHighlightsAndMediaTexts(props.document)
      // User can't move draft with highlights to finish step.
      if (highlights) {
        setWrongTarget(prevState => ({
          ...prevState,
          show: true,
          l10n: 'step-changer-dialog-wrong-target-highlights',
          highlights,
        }))
        return
      }

      // User can't move draft with media_text elements to finish step.
      if (mediaTexts) {
        setWrongTarget(prevState => ({
          ...prevState,
          wrongTargetDialog: true,
          wrongTargetL10n: 'step-changer-dialog-wrong-target-media-text',
          mediaTexts,
        }))
        return
      }
    }

    if (draftPermissions.some(p => ['accept-changes', 'propose-changes'].includes(p))) {
      const suggestions = SuggestionsEditor.isSuggestionsEditor(editor)
        ? Array.from(Editor.nodes(editor, { match: Suggestion.is })).length
        : 0

      // User with accept-changes permission have to accept / decline all of the suggestions.
      if (draftPermissions.includes('accept-changes') && suggestions) {
        if (!stepHasPermission(targetStep, 'accept-changes') && !stepHasPermission(targetStep, 'propose-changes')) {
          setSuggestions({ show: true, counter: suggestions })
        }
        return
      }

      // User with propose-changes cannot move draft to step other than accept-changes
      // if there are unresolved suggestions.
      if (draftPermissions.includes('propose-changes')) {
        if (!stepHasPermission(targetStep, 'accept-changes') && suggestions) {
          setWrongTarget(prevState => ({
            ...prevState,
            wrongTargetDialog: true,
            wrongTargetL10n: 'step-changer-dialog-wrong-target-suggestions',
          }))
          setSuggestions(prevState => ({ ...prevState, counter: suggestions }))
          return
        }
      }
    }

    setHasUnsavedChanges(props.hasChanges)
    setShowConfirmDialog(true)
    setShowDetailsDialog(false)
  }

  const saveAndAdvance = async (targetLink: Link) => {
    setIsAdvancing(true)
    try {
      await storage.write(editor)
      if (PersistingEditor.isPersistingEditor(editor)) {
        await editor.documentDB.save(editor.children, storage.tag)
      }
      nextStep(targetLink)
    } catch (ex) {
      store.dispatch(addAlert('error', 'step-changer-save-advance-error', {
        details: ex.response ? ex.response.data.raw : ex.toString(),
      }))
    } finally {
      setIsAdvancing(false)
    }
  }

  const nextStep = (targetLink: Link) => {
    setIsAdvancing(true)
    props.draft.advance({ target: targetLink.to, slot: targetLink.slot })
      .then(async res => {
        store.dispatch(addAlert('success', 'step-changer-success', {
          code: res.code.replace(/:/g, '-'),
        }))
        props.onStepChange()
        await Module.load(props.draft.module)
      })
      .catch(e => {
        if (e?.response?.data?.error === 'draft:advance:validation-failed') {
          setValidationErrors(e.response.data.data)
        } else {
          store.dispatch(addAlert('error', 'step-changer-error', {
            details: e.response.data.raw,
          }))
        }
      })
      .finally(() => {
        setIsAdvancing(false)
      })
  }

  const getL10nTotalForWrongTargetDialog = () => {
    if (wrongTarget.l10n === 'step-changer-dialog-wrong-target-suggestions') {
      return suggestions.counter
    } else if (wrongTarget.l10n === 'step-changer-dialog-wrong-target-highlights') {
      return wrongTarget.highlights
    } else if (wrongTarget.l10n === 'step-changer-dialog-wrong-target-media-text') {
      return wrongTarget.mediaTexts
    }
    return 0
  }

  const clearValidationErrors = () => {
    setValidationErrors(null)
  }

  if (!props.draft.step || !props.draft.step.links.length) return null

  return (
    <div className="step-changer">
      <Button l10nId="step-changer-main-button" clickHandler={() => setShowDetailsDialog(true)} withBorder={true}>
        I am handing my work to the next step
      </Button>
      <InvalidReferencesDialog
        references={invalidReferences}
        onClose={() => setInvalidReferences([])}
      />
      <DetailsDialog
        show={showDetailsDialog}
        step={props.draft.step}
        onClose={() => setShowDetailsDialog(false)}
        onStepChange={l => {
          setLink(l)
          displayConfirmDialog(l)
        }}
      />
      <SuggestionsDialog
        show={suggestions.show}
        suggestions={suggestions.counter}
        onClose={() => setSuggestions(initialStateSuggestions)}
      />
      <WrongTargetDialog
        show={wrongTarget.show}
        wrongTargetL10n={wrongTarget.l10n}
        total={getL10nTotalForWrongTargetDialog()}
        onClose={() => setWrongTarget(initialStateWrongTarget)}
      />
      {link && showConfirmDialog &&
        <ConfirmDialog
          isAdvancing={isAdvancing}
          link={link}
          unsavedChanges={hasUnsavedChanges}
          onClose={() => setShowConfirmDialog(false)}
          onNextStep={nextStep}
          onSaveAndAdvance={saveAndAdvance}
        />}
      <ValidationErrorsDialog
        title="step-changer-error-validation"
        errors={validationErrors}
        clearValidationErrors={clearValidationErrors}
      />
    </div>
  )
}

export default StepChanger
