import { Editor, Element, Node, Text } from 'slate'

export function shouldHaveInlines(editor: Editor, node: Node): boolean {
    return Editor.isEditor(node)
        ? false
        : Element.isElement(node) && (editor.isInline(node) || node.children.length === 0
            || Text.isText(node.children[0] || editor.isInline(node.children[0])))
}

export function markAsInsertSuggestion(node: Node): Node {
    if (Element.isElement(node)) {
        return {
            ...node,
            suggestion: 'insert',
            children: node.children.map(markAsInsertSuggestion),
        }
    } else if (node.text !== '') {
        return {
            ...node,
            suggestion: 'insert',
        }
    }
    return node
}
