/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://1d6b98abb755b837251990dd8adf2f95@sentry.naukosfera.com/5'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = '85ae62dbabd49641d063e49a7ffc0bf2227d57e9'
