/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://1d6b98abb755b837251990dd8adf2f95@sentry.naukosfera.com/5'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = '7428277422d10309b3fef16a55a0deb0f594ff02'
