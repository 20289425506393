import * as React from 'react'
import { connect } from 'react-redux'
import { Book } from '../../../api'
import { State } from '../../../store/reducers/index'
import Section from '../../../components/Section'
import Header from '../../../components/Header'
import LimitedUI from '../../../components/LimitedUI'
import PageNavigation from '../../../components/PageNavigation'
import LockToggle from '../../../components/LockToggle'
import BooksManager from './components/BooksManager'
import ContentTools from '../../../components/ContentTools'
import './index.css'

interface BooksProps {
  selectedTeams: number[]
}

export const mapStateToProps = ({ app: { selectedTeams } }: State) => ({
  selectedTeams,
})

interface BooksState {
  showAddBook: boolean
  isEditingUnlocked: boolean
}

class Books extends React.Component<BooksProps> {
  state: BooksState = {
    showAddBook: false,
    isEditingUnlocked: false,
  }

  private showAddBookDialog = () => {
    this.setState({ showAddBook: true })
  }

  private closeAddBookDialog = () => {
    this.setState({ showAddBook: false })
  }

  private toggleEditing = (status: boolean) => {
    this.setState({ isEditingUnlocked: status })
  }

  public render() {
    const { showAddBook, isEditingUnlocked } = this.state

    return (
      <Section>
        <Header l10nId="book-list-view-title" title="Books">
          <LimitedUI permissions="book:edit">
            <LockToggle onToggle={this.toggleEditing} />
            {isEditingUnlocked ? <ContentTools /> : null}
          </LimitedUI>
        </Header>
        <div className="section__content books">
          <PageNavigation
            pagination={Book.pagination()}
            usePageParam={true}
            Component={
              ({ items, onAdd, onDelete }) => (
                <BooksManager
                  books={items}
                  showAddBook={showAddBook}
                  onCloseAddBook={this.closeAddBookDialog}
                  isEditingUnlocked={isEditingUnlocked}
                  onAdd={onAdd}
                  onDelete={onDelete}
                />
              )
            }
          />
        </div>
      </Section>
    )
  }
}

export default connect(mapStateToProps)(Books)
