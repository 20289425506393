import * as React from 'react'
import { Localized } from '@fluent/react'
import { Draft, Process } from '../../api'
import Dialog from '../../components/ui/Dialog'
import Button from '../../components/ui/Button'

import './index.css'

interface DraftInfoProps {
  draft: Draft
  showProcess?: boolean
  showStep?: boolean
  showPermissions?: boolean
}

class DraftInfo extends React.Component<DraftInfoProps> {
  state: {
    process: Process | null
    showDialog: boolean
  } = { process: null, showDialog: false }

  private showDraftInfo = () => {
    this.setState({ showDialog: true })
  }

  private closeDraftInfo = () => {
    this.setState({ showDialog: false })
  }

  private getProcess = async () => {
    const { draft: { step } } = this.props
    if (!step) return

    this.setState({ process: await Process.load(step.process[0]) })
  }

  componentDidMount() {
    this.getProcess()
  }

  public render() {
    const {
      draft,
      showProcess = true,
      showStep = true,
      showPermissions = true,
    } = this.props
    const { process, showDialog } = this.state

    if (!draft.permissions || !draft.step) return null

    return (
      <div className="draft-info">
        <Button id="draft-info-main-button" clickHandler={this.showDraftInfo} withBorder={true}>
          <Localized id="draft-info-main-button">
            Informations about process
          </Localized>
        </Button>
        {
          showDialog ?
            <Dialog
              size="medium"
              l10nId="draft-info-title"
              placeholder="Informations about process for this draft"
              onClose={this.closeDraftInfo}
            >
              <div className="draft-info__content">
                {
                  showProcess && <span className="draft-info__process">
                    <Localized
                      id="draft-info-process"
                      vars={{ process: process ? process.name : 'undefined' }}
                    >
                      {'Process: { $process }'}
                    </Localized>
                  </span>
                }
                {
                  showStep && <span className="draft-info__step">
                    <Localized
                      id="draft-info-step"
                      vars={{ step: draft.step.name }}
                    >
                      {'Current step: { $step }'}
                    </Localized>
                  </span>
                }
                {
                  showPermissions && <span className="draft-info__permissions">
                    <Localized id="draft-info-permissions">
                      Your permissions:
                    </Localized>
                    {
                      draft.permissions.map(p => (
                        <span key={p} className="draft-info__permission">
                          <Localized
                            id="draft-info-permission"
                            vars={{ permission: p }}
                          >
                            {p}
                          </Localized>
                        </span>
                      ))
                    }
                  </span>
                }
              </div>
            </Dialog>
            : null
        }
      </div>
    )
  }
}

export default DraftInfo
