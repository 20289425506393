import * as React from 'react'
import Files, { FilesError, FilesRef } from 'react-files'
import { Localized } from '@fluent/react'
import Button from '../../components/ui/Button'
import Icon from '../../components/ui/Icon'

import './index.css'

interface FileUploaderProps {
  onFilesChange: (files: File[]) => any
  onFilesError: (error: FilesError, file: File) => any
  className?: string
  optional: boolean
  dropActiveClassName?: string
  accepts: string[]
  multiple?: boolean
  maxFiles?: number
  maxFileSize?: number
  minFileSize?: number
  clickable?: boolean
  localizedComponent?: JSX.Element
}

interface FileUploaderState {
  files: File[]
}

class FileUploader extends React.Component<FileUploaderProps> {
  state: FileUploaderState = {
    files: [],
  }

  private onFilesChange = (files: File[]) => {
    this.setState({ files })
    this.props.onFilesChange(files)
  }

  public filesRemoveOne = (file: File) => {
    const files = this.filesRef.current
    if (files) {
      files.removeFile(file)
    }
  }

  public filesRemoveAll = () => {
    const files = this.filesRef.current
    if (files) {
      files.removeFiles()
    }
  }

  filesRef: React.RefObject<FilesRef> = React.createRef()

  public render() {
    const files = this.state.files
    const { onFilesChange, onFilesError, optional, localizedComponent, ...otherProps } = this.props
    const multiple = typeof otherProps.multiple === 'boolean' ?
      otherProps.multiple
      : otherProps.maxFiles !== 1

    return (
      <div className="files-uploader">
        <Files
          ref={this.filesRef}
          onChange={this.onFilesChange}
          onError={onFilesError}
          {...otherProps}
        >
          {
            localizedComponent ||
            <Localized
              id="file-upload-select-files"
              vars={{
                multiple: multiple.toString(),
                optional: optional.toString(),
              }}
            >
              Drop files here or click to upload (optional).
            </Localized>
          }
        </Files>
        {
          files.length ?
            <>
              {
                multiple ?
                  <Button l10nId="file-upload-remove-all" type="danger" clickHandler={this.filesRemoveAll}>
                    Remove all files
                  </Button>
                  : null
              }
              <ul className="files__list">
                {
                  files.map((file: File, index: number) => (
                    <FileItem
                      key={file.name + index}
                      file={file}
                      onClickRemove={this.filesRemoveOne}
                    />
                  ))
                }
              </ul>
            </>
            : null
        }
      </div>
    )
  }
}

export default FileUploader

interface FileItemProps {
  file: File
  onClickRemove: (file: File) => void
}

const FileItem = ({ file, onClickRemove }: FileItemProps) => {
  const onClick = () => {
    onClickRemove(file)
  }
  return (
    <li className="files__file">
      <span className="files__name">
        {file.name}
      </span>
      <span
        id="file-item-close"
        className="files__close"
        onClick={onClick}
      >
        <Icon name="close" />
      </span>
    </li>
  )
}
