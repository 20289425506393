import {
    Editor,
    Element,
    Node,
    Path,
} from 'slate'

import { Marker, Suggestion } from './suggestion'
import { DISABLE_SUGGESTING } from './maps'

/**
 * Slate editor with support for recording suggestions
 */
export interface SuggestionsEditor extends Editor {
    /**
     * Current editing mode
     *
     * When this property is set to {@code 'suggesting'} (and suggesting isn't
     * disabled by {@link SuggestionsEditor.withoutSuggesting}) every operation
     * applied to this editor will be treated as a suggestion. When it is set to
     * anything other than {@code 'suggesting'} operations will be passed
     * unmodified.
     *
     * Other plugins may define additional values.
     */
    editingMode: 'normal' | 'suggesting'

    /**
     * Generate an ID for a node
     *
     * These IDs are used to track movement of nodes.
     *
     * The default implementation generates simple, sequential, integer IDs, and
     * does not enforce uniqueness. You should replace this with your own
     * implementation.
     */
    generateID: () => string
}

export const SuggestionsEditor = {
    /**
     * Check if a value is an `SuggestionsEditor` object
     */
    isSuggestionsEditor(value: unknown): value is SuggestionsEditor {
        return Editor.isEditor(value)
            && typeof value.editingMode === 'string'
    },

    /**
     * Check if operations are processed normally or as suggestions.
     */
    isSuggesting(editor: SuggestionsEditor): boolean {
        return !(DISABLE_SUGGESTING.get(editor) ?? false) && editor.editingMode === 'suggesting'
    },

    /**
     * Call a function, without marking changes made by it as suggestions.
     */
    withoutSuggesting(editor: SuggestionsEditor, fn: () => void): void {
        const value = DISABLE_SUGGESTING.get(editor) ?? false
        DISABLE_SUGGESTING.set(editor, true)
        fn()
        DISABLE_SUGGESTING.set(editor, value)
    },

    /**
     * Accept suggestions
     */
    accept(
        editor: SuggestionsEditor,
        options: {
            at: Range | Path,
            type: 'insert' | 'remove' | 'move' | 'split' | 'merge',
        },
    ): void {
        Editor.withoutNormalizing(editor, () => {
            resolveSuggestions(editor, { ...options, resolution: 'accept' })
        })
    },

    /**
     * Reject suggestions
     */
    reject(
        editor: SuggestionsEditor,
        options: {
            at: Range | Path,
            type: 'insert' | 'remove' | 'move' | 'split' | 'merge',
        },
    ): void {
        Editor.withoutNormalizing(editor, () => {
            resolveSuggestions(editor, { ...options, resolution: 'reject' })
        })
    },
}

function resolveSuggestions(
    editor: SuggestionsEditor,
    options: {
        at: Range | Path,
        type: 'insert' | 'remove' | 'move' | 'split' | 'merge',
        resolution: 'accept' | 'reject',
    },
) {
    const { at, type, resolution } = options

    if (Path.isPath(at)) {
        const node = Node.get(editor, at)

        if ((type === 'insert' && Suggestion.isInsert(node))
        || (type === 'remove' && Suggestion.isRemove(node))
        || (type === 'move' && Suggestion.isMove(node))
        || (type === 'split' && Suggestion.isSplit(node))
        || (type === 'merge' && Marker.isJoin(node))) {
            resolveSuggestion(editor, at, node, type, resolution)
        }

        return
    }

    throw Error("not implemented")
}

function resolveSuggestion(
    editor: SuggestionsEditor,
    path: Path,
    node: Node,
    type: 'insert' | 'remove' | 'move' | 'split' | 'merge',
    resolution: 'accept' | 'reject',
) {
    switch (type) {
    case 'insert':
        // Rejecting an insert suggestion will remove the node, including all of
        // its children. We might not want that, as the inserted node may be
        // wrapping existing nodes.
        if (resolution === 'reject' && Element.isElement(node)) {
            for (let inx = node.children.length - 1; inx >= 0; --inx) {
                const child = node.children[inx]

                if (Suggestion.isMove(child)) {
                    // Move suggestion - reject it
                    resolveSuggestion(editor, [...path, inx], child, 'move', 'reject')
                }
            }
            node = Node.get(editor, path)
        }

        editor.apply({
            type: 'insert_node',
            suggestion: resolution,
            change: 'insert',
            path,
            node,
        })
        break

    case 'remove':
        editor.apply({
            type: 'remove_node',
            suggestion: resolution,
            change: 'remove',
            path,
            node,
        })
        break

    case 'move': {
        const [[marker, markerPath]] = Editor.nodes(editor, {
            at: [], // search the whole document
            match: marker => Marker.isMoveSource(marker) && marker.id === node.movedFrom,
        })

        /* istanbul ignore next */
        if (marker == null) {
            throw Error(
                `the source (ID ${node.movedFrom as string}) of suggestion at \
                ${JSON.stringify(path)} not found`,
            )
        }

        // We need to use paths as they were (or would have been) before the
        // move operation
        const originalPath = Path.transform(markerPath, {
            type: 'remove_node',
            path,
            node: { text: '' },
        })!
        const newPath = Path.isSibling(originalPath, path)
            ? Path.transform(path, {
                type: 'remove_node',
                path: markerPath,
                node: { text: '' },
            })!
            : path

        editor.apply({
            type: 'move_node',
            suggestion: resolution,
            change: 'move',
            path: originalPath,
            originalPath,
            newPath,
        })
        break
    }

    case 'split': {
        const [[marker, markerPath]] = Editor.nodes(editor, {
            at: [], // search the whole document
            match: marker => Marker.isSplit(marker) && marker.id === node.splitFrom,
        })

        /* istanbul ignore next */
        if (marker == null) {
            throw Error(
                `the source (ID ${node.splitFrom as string}) of suggestion at \
                ${JSON.stringify(path)} not found`,
            )
        }

        const parentPath = Path.parent(markerPath)
        const parent = Node.ancestor(editor, parentPath)

        editor.apply({
            type: 'split_node',
            suggestion: resolution,
            change: 'split',
            path: parentPath,
            position: parent.children.indexOf(marker),
            properties: {}, // ignored when accepting/rejecting
        })
        break
    }

    case 'merge':
        editor.apply({
            type: 'merge_node',
            suggestion: resolution,
            change: 'merge',
            path: Path.next(path.slice(0, path.length - 1)),
            position: path[path.length - 1],
            properties: {}, // ignored when accepting/rejecting
        })
        break
    }
}
