import * as React from 'react'
import { Localized } from '@fluent/react'
import { Editor, Node, Path, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { Marker, Suggestion, SuggestionsEditor } from 'slate-suggestions'
// import { Paragraph } from 'cnx-designer'
// import path from 'path'
// import { Paragraph } from 'cnx-designer'
import Button from '../../../../../../../components/ui/Button'
import Checkbox from '../../../../../../../components/ui/Checkbox'
import Icon from '../../../../../../../components/ui/Icon'
import { CurrentDraftContext } from '../../../../../../../screens/app/Draft'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import SuggestionBox from './SuggestionBox'
import Modal from '../../../../../../../components/Modal'
// import { SuggestionEx } from './types'
// import {
//   acceptOrRejectSuggestion,
//   findActiveSuggestionIndexByRef,
//   getPointBeforeSuggestion,
// } from './utils'
import { acceptOrRejectAll } from './utils'

import './index.css'

interface SuggestionProps {
  toggleState: boolean
  editor: ReactEditor
  onToggle: OnToggle
  showSuggestions: boolean
  setShowSuggestions: (val: boolean) => void
}

const SuggestionsTools = ({
  toggleState,
  editor,
  onToggle,
  showSuggestions,
  setShowSuggestions,
}: SuggestionProps) => {
  if (!SuggestionsEditor.isSuggestionsEditor(editor)) {
    throw new Error('Expected editor to be an instance of SuggestionsEditor')
  }

  const selection = useCurrentSelection()
  const context = React.useContext(CurrentDraftContext)
  const draftPermissions = context.state.currentDraftPermissions
  const [activeSuggestion, setActiveSuggestion] = React.useState<any>(null)
  const [nextSuggestion, setNextSuggestion] = React.useState<any>(null)
  const [prevSuggestion, setPrevSuggestion] = React.useState<any>(null)
  const [changeSelectionOnAccRej, setChangeSelectionOnAccRej] = React.useState(true)
  const modal = React.useRef<Modal>(null)

  React.useEffect(() => {
    if (selection) {
      const selectedNode = Editor.node(editor, selection)
      let previous
      let selectedSuggestion

      if (selection.anchor.path[selection.anchor.path.length - 1] > 0) {
        previous = Editor.node(editor, Path.previous(selection.anchor.path))
      }
      if ('suggestion' in selectedNode[0]) { selectedSuggestion = selectedNode }
      else if (selection.anchor.offset === 0 && previous && Suggestion.is(previous[0])) {
        selectedSuggestion = previous
      }
      else {
        const above = Editor.above(editor, {
          match: n => Suggestion.is(n) || Marker.is(n),
        })
        if (above) selectedSuggestion = above
      }
      findNextAndPrevious()
      if (selectedSuggestion) {
        return setActiveSuggestion(selectedSuggestion)
      }
    }
    return setActiveSuggestion(undefined)
  // eslint-disable-next-line
  }, [editor, editor.children, selection])

  const findNextAndPrevious = () => {
    setNextSuggestion(Editor.next(editor, { match: Suggestion.is || Marker.is }))
    setPrevSuggestion(Editor.previous(editor, { match: Suggestion.is || Marker.is }))
  }

  const selectNextOrPrev = (mode: "next" | "prev") => {
    const sugg = mode === "next" ? nextSuggestion : prevSuggestion
    if (!sugg) return
    const path = [...sugg[1]]
    if (sugg[0].type === "paragraph" || sugg[0].type ==="table_entry") path.push(0)
    Transforms.select(editor, path)

    const node = ReactEditor.toDOMNode(editor, Node.get(editor, path))
    node.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const acceptAll = React.useCallback(() => acceptOrRejectAll(editor, 'accept'), [editor])
  const rejectAll = React.useCallback(() => modal.current?.open(), [modal])

  const renderModal = React.useCallback(() => (
    <div className="suggestionBox__modal--removeAll">
      <div className="suggestionBox__modal--removeAll_text">
        <Localized id="suggestionBox__modal--removeAll">
            Show changes in editor
        </Localized>
      </div>
      <div className="suggestionBox__modal--removeAll-buttons">
        <Button
          id="suggestionBox__modal--removeAll_button_yes"
          clickHandler={() => {
            modal.current?.close()
            return acceptOrRejectAll(editor, 'reject'), [editor]
          }}
          className="suggestionBox__modal--removeAll_button"
        >
          <Localized id="suggestionBox__button--removeAll">
            Jo
          </Localized>
        </Button>
        <Button
          id="suggestionBox__modal--removeAll_button_no"
          clickHandler={() => modal.current?.close()}
          className="suggestionBox__modal--removeAll_button"
        >
          <Localized id="suggestionBox__button--declineRemoveAll">
            Nein
          </Localized>
        </Button>
      </div>
    </div>
  ), [editor])


  return (
    <ToolGroup
      title="editor-tools-suggestions-title"
      toggleState={toggleState}
      onToggle={() => onToggle('suggestionsTools')}
    >
      <div className='suggestion-tools__checkbox-with-label'>
        <p className='suggestion-tools__label'>
          <Localized id="editor-tools-suggestions-show-hide">
            Show changes in editor
          </Localized>
        </p>
        <p className='suggestion-tools__checkbox' data-testid="show-entire-adjustment">
          <Checkbox
            value={showSuggestions}
            onChange={setShowSuggestions}
          />
        </p>
      </div>
      <div className='suggestion-tools__box_area'>
        <div className='suggestion-box__control_arrows_div'>
          <Button
            id="editor-tools-suggestions-prev"
            className="toolbox__button--only-icon suggestion-box__control"
            clickHandler={() => selectNextOrPrev("prev")}
            isDisabled={!prevSuggestion}
          >
            <Icon size="small" name="arrow-left" />
          </Button>
          <Button
            id="editor-tools-suggestions-next"
            className="toolbox__button--only-icon suggestion-box__control"
            clickHandler={() => selectNextOrPrev("next")}
            isDisabled={!nextSuggestion}
          >
            <Icon size="small" name="arrow-right" />
          </Button>
        </div>
        {activeSuggestion && (
          <SuggestionBox
            editor={editor}
            suggestion={activeSuggestion}
            draftPermissions={draftPermissions}
            onAccept={SuggestionsEditor.accept}
            onDecline={SuggestionsEditor.reject}
            selectNextOrPrev={selectNextOrPrev}
            changeSelectionOnAccRej={changeSelectionOnAccRej}
          />
        )}
      </div>
      {activeSuggestion && <>
        <div className='suggestion-tools__checkbox-with-label'>
          <p className='suggestion-tools__label'>
            <Localized id="editor-tools-suggestions-move-after-acc-rej">
              After accepting/rejecting move to the next suggestion
            </Localized>
          </p>
          <p className='suggestion-tools__checkbox' data-testid="skip-to-next-suggestion-after-accept">
            <Checkbox
              value={changeSelectionOnAccRej}
              onChange={() => setChangeSelectionOnAccRej(!changeSelectionOnAccRej)}
            />
          </p>
        </div>
        <div className="suggestion__controls">
          {
            draftPermissions.includes('accept-changes') ?
              <Button
                l10nId="editor-tools-suggestion-accept-all"
                clickHandler={acceptAll}
                className='suggestion__controls-acceptAll'
              >
                Accept all
              </Button>
              : null
          }
          <Button
            l10nId="editor-tools-suggestion-reject-all"
            clickHandler={rejectAll}
            className='suggestion__controls-rejectAll'
          >
            Reject all
          </Button>
        </div>
      </> }
      <Modal
        ref={modal}
        showCloseButton={false}
        closeOnBgClick={false}
        closeOnEsc={false}
        content={renderModal}
      />
    </ToolGroup>
  )
}

export default SuggestionsTools
